import React, { useCallback, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";

// components
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../components/hero/hero-chevron";
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import Button from "../../components/custom-widgets/button";
import Icon from "../../components/custom-widgets/icon";
import SearchSuggestionsInput from "../../components/custom-widgets/search-suggestions-input";
import BankerHeadshot from '../../components/banker-headshot'

const IconsData = {
  waivedFees: "/images/icons/waived-fees-icon-128.svg",
  reliablePartnership: "/images/icons/reliable-partnership-icon-128.svg",
  enhancedBanking: "/images/icons/enhanced-banking-management-icon-128.svg",
  accountSecurity: "/images/icons/account-security-icon-128.svg"
};

const SelectBanking = () => {
  const title = "Select Banking",
    description =
      "Your WaFd Bank private banker will get to know you and can help you select the best banking services for your borrowing and cash management needs.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/private-banking-services/hero-select-banking-011823-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      defaultProfilePhoto: file(
        relativePath: { eq: "employees/branch-managers/wafd-bank-branch-manager-default.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 90
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const heroChevronData = {
    id: "select-banking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Two businesswomen reviewing a document on a tablet.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Select Banking Is All About Relationships"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/private-banking-services"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-select-banking-011823-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Select Banking"
    }
  ];
  const BankersMarkup = ({ banker }) => {
    // console.log("THE BANKER: " + JSON.stringify(banker));
    /*
      banker: {
        node: {
          id
          State
          Name
          PhoneNumber
          EmailAddress
          ProfilePhoto: {
            childImageSharp: {
              id
              gatsbyImageData
            }
          }
        }
    */
    return (
      <div className="col-md-6 mb-5 px-1">
        <div className="row mx-0">
          <div className="mr-4">
            <BankerHeadshot email={banker.EmailAddress}/>
          </div>
          <div className="col px-0">
            <h5 id={banker.Name.toLowerCase().replace(/ +/g, "-")}>{banker.Name}</h5>
            <p className="font-weight-semi-bold" id={banker.State.toLowerCase().replace(/ +/g, "-")}>
              {banker.State.toLowerCase() === "newmexico" ? "New Mexico" : banker.State}
            </p>

            <div className="mb-3">
              <a
                href={`tel:${banker.PhoneNumber}`}
                id={`select-banker-phone-${banker.id}`}
                className="text-decoration-none"
              >
                <Icon class="mr-2 text-blue-60" name="phone-alt" />
                {banker.PhoneNumber}
              </a>
            </div>

            <div className="mb-3">
              <a
                href={`mailto:${banker.EmailAddress}`}
                id={`select-banker-email-${banker.id}`}
                className="text-decoration-none"
              >
                <Icon class="mr-2 text-blue-60" name="envelope-open-text" />
                {banker.EmailAddress}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [query, setQuery] = useState("");

  const [querySuggestions, setQuerySuggestions] = useState({ hits: [] });
  const [selectBankers, setSelectBankers] = useState([]);
  const [search, setSearch] = useState("");

  const renderBankers = useCallback(() => {
    return selectBankers.map((banker) => {
      return <BankersMarkup banker={banker.node} />;
    });
  }, [selectBankers]);

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <div className="row">
          <div className="col-md-6 mb-4 mb-md-0">
            <StaticImage
              src="../../images/select-banking-600.jpg"
              alt="Business partners shaking hands and smiling in the office."
              quality="100"
              placeholder="blurred"
            />
          </div>
          <div className="col-md-6">
            <h1>Select Banking</h1>
            <p>
              Two heads are better than one. That's why Select Banking is a joint effort - WaFd Bank and you. We thrive
              when we bring you to a place of financial success through the insightful management of your resources and
              concierge-level service. We'll stand ready to meet your banking needs because we know it takes
              collaboration to achieve your goals.
            </p>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center d-block d-md-none">
              <StaticImage
                src="../../images/credit-cards/thumbnail-credit-select-angled.png"
                alt="WaFd Bank Visa Select Credit Card"
                placeholder="blurred"
                quality="100"
                style={{ transform: "rotate(30deg)" }}
                height={340}
              />
            </div>
            <div className="col-md-6 py-4 my-2">
              <h2 className="text-success">Select Credit Card</h2>
              <p>
                As a Select Banking client, you are invited to apply for our Select Credit Card. This card has an
                exclusively low, competitive rate and a balance transfer option (available post-enrollment) with no
                minimums or fees, along with the 24/7 account support you deserve.
              </p>
              <Button
                id="find-a-select-banker-cta"
                url="#wafd-select-bankers"
                text="Find a Select Banker"
                class="btn-primary "
                containerClass="text-center text-md-left"
              />
            </div>

            <div className="col-md-6 text-center d-none d-md-block">
              <StaticImage
                src="../../images/cards/credit-cards/credit-card-select-visa.png"
                alt="WaFd Bank Visa Select Credit Card"
                placeholder="blurred"
                quality="100"
                style={{ marginTop: "-30px", marginBottom: "-40px", transform: "rotate(30deg)" }}
                height={340}
              />
            </div>
          </div>
        </div>
      </div>
      <section className="container border-bottom-2 pt-0 my-5">
        <div className="row">
          <div className="col-lg-6">
            <h2>Benefits of Select Banking</h2>
            <p>Our clients depend on us for timely and insightful management of their resources.</p>
            <p>
              This requires attention to detail and personalized service. We invite clients who maintain personal
              qualifying balances with us to receive the benefits of Select Banking:
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6 mb-3">
            <div className="d-sm-flex">
              <div className="col-auto">
                <div
                  className="bg-success d-flex align-items-center justify-content-center mx-auto mb-3"
                  style={{ borderRadius: "50%", height: "128px", width: "128px" }}
                >
                  <img src={IconsData.reliablePartnership} alt="" />
                </div>
              </div>
              <div className="col">
                <h4 className="font-weight-bold" id="select-banking-title-1">
                  Reliable Partnership
                </h4>
                <ul>
                  <li id="reliable-partnership-1">Dedicated Select Banking Relationship Manager</li>
                  <li id="reliable-partnership-2">First-in-line 24-hour customer service</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="d-sm-flex">
              <div className="col-auto">
                <div
                  className="bg-success d-flex align-items-center justify-content-center mx-auto mb-3"
                  style={{ borderRadius: "50%", height: "128px", width: "128px" }}
                >
                  <img src={IconsData.accountSecurity} alt="" />
                </div>
              </div>
              <div className="col">
                <h4 className="font-weight-bold" id="select-banking-title-2">
                  Account Security
                </h4>
                <ul>
                  <li id="account-security-1">
                    Complimentary credit monitoring, identity protection and theft resolution, plus buyer's protection
                  </li>
                  <li id="account-security-2">Complimentary safe deposit box, where available</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="d-sm-flex">
              <div className="col-auto">
                <div
                  className="bg-success d-flex align-items-center justify-content-center mx-auto mb-3"
                  style={{ borderRadius: "50%", height: "128px", width: "128px" }}
                >
                  <img src={IconsData.waivedFees} alt="" />
                </div>
              </div>
              <div className="col">
                <h4 className="font-weight-bold" id="select-banking-title-3">
                  Waived Fees
                </h4>
                <ul>
                  <li id="waived-fees-1">No-fee personal wire transfers</li>
                  <li id="waived-fees-2">Reduced-loan fees on primary and secondary home financing*</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="d-sm-flex">
              <div className="col-auto">
                <div
                  className="bg-success d-flex align-items-center justify-content-center mx-auto mb-3"
                  style={{ borderRadius: "50%", height: "128px", width: "128px" }}
                >
                  <img src={IconsData.enhancedBanking} alt="" />
                </div>
              </div>
              <div className="col">
                <h4 className="font-weight-bold" id="select-banking-title-4">
                  Enhanced Banking Management
                </h4>
                <ul>
                  <li id="enhanced-banking-1">Higher daily debit transaction limits</li>
                  <li id="enhanced-banking-2">Select Banking Credit Card</li>
                  <li id="enhanced-banking-3">Complimentary checks</li>
                  <li id="enhanced-banking-4">Daily debit transactions up to $5,000</li>
                  <li id="enhanced-banking-5">Personal line of credit*</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p className="text-sm text-muted" id="select-banking-eligibility-1">
          *On approved credit. Must meet minimum deposit balance requirements of $250,000 or more, with qualifying
          balance tiers. Not all clients may be eligible. This product is for consumer clients only.
        </p>
      </section>

      <section className="container pt-0" id="wafd-select-bankers">
        <h2>Meet our Select Bankers</h2>

        <SearchSuggestionsInput
          type="select-banking"
          query={query}
          setQuery={setQuery}
          querySuggestions={querySuggestions}
          setQuerySuggestions={setQuerySuggestions}
          search={search}
          setSearch={setSearch}
          setSelectBankers={setSelectBankers}
        />

        {selectBankers && selectBankers.length > 0 && <div className="row mx-0 mt-4">{renderBankers()}</div>}
        <div className="row">
          <div className="col-md-6">
            <p id="select-banking-contact">
              For regions without a designated Select Banker, reach out to{" "}
              <a href="mailto:selectbanking@wafd.com" id="select-banking-email">
                selectbanking@wafd.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default SelectBanking;
